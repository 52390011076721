<template>
  <div class="headerAll" :class="{enHeader: isEnHeader}">
    <div class="headerTop">
      <div class="headerTopContainer">
        <el-row>
          <el-col :span="msgSpanCss.LoginSpanCss">
            <div v-if="sessionInfoGetter.isLogin=='true'">
              <el-row>
                <el-col :span="2">
                  <span class="com">您好，</span>
                </el-col>
                <el-col :span="4" id="telSpan">
                  <span>{{sessionInfoGetter.tel}}</span>
                </el-col>
                <el-col :span="2">
                  <span @click="logout()" class="com exit">退出</span>
                </el-col>
              </el-row>
            </div>
            <div v-else>
              <el-row>
                <el-col :span="2">
                  <router-link to="/login">
                    <span>登录</span>
                  </router-link>
                </el-col>
                <el-col :span="4">
                  <router-link to="/register/info">
                    <span>注册</span>
                  </router-link>
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="8" :offset="6">
            <div class="headerTopLeftNav">
              <div
                class="navList"
                v-if="sessionInfoGetter.isLogin=='true' && userTypeGetter == '2'"
              >
                <router-link to="/matching">
                  <div class="headerTopRight highlightColor">买家中心</div>
                </router-link>
              </div>
              <div
                class="navList"
                v-if="sessionInfoGetter.isLogin=='true' && userTypeGetter == '3'"
              >
                <router-link to="/sellerCenter">
                  <div class="headerTopRight highlightColor">卖家中心</div>
                </router-link>
              </div>
              <div
                class="navList"
                v-if="sessionInfoGetter.isLogin=='true' && userTypeGetter == '2'"
              >
                <router-link to="/productionSell/cart">
                  <div class="headerTopRight">产能购物车</div>
                </router-link>
              </div>
              <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/capitalCenter/info">
                  <div class="headerTopRight">资产中心</div>
                </router-link>
              </div>
              <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/personalCenter">
                  <div class="headerTopRight">{{ userCenterText }}</div>
                </router-link>
              </div>
              <div class="navList" v-if="sessionInfoGetter.isLogin=='true'">
                <router-link to="/mall/user">
                  <div class="headerTopRight">地址管理</div>
                </router-link>
              </div>
              <!-- <div class="navList">
                <div class="headerTopRight">消息中心</div>
              </div>
              <div class="navList">
                <div class="headerTopRight">帮助中心</div>
              </div>
              <div class="navList">
                <div class="headerTopRight">在线客服</div>
              </div>-->
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="headerBottom">
      <el-row>
        <el-col :span="3">
          <router-link tag="li" to="/index">
            <div class="homeImgBox">
              <p>LOGO</p>
              <!-- <img src="../../../assets/configImages/home.png" class="homeImg"> -->
            </div>
          </router-link>
        </el-col>
        <el-col :span="21">
          <el-row>
            <el-col :span="24">
              <div class="search">
                <form>
                  <input type="text" class="searchInput" placeholder="请输入您想要搜索的关键词" />
                  <input type="submit" class="searchSubmit" value="搜索" @click="goSearch()" />
                </form>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="3">
              <router-link tag="li" to="/index">
                <div class="com">
                  <span class="comtext">首页</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="3">
              <router-link tag="li" to="/ResourceDocuments">
                <div class="com">
                  <span class="comtext">资源单</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="3">
              <router-link tag="li" to="/purchaseDocument">
                <div class="com">
                  <span class="comtext">求购单</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="3">
              <router-link tag="li" to="/productionSell">
                <div class="com">
                  <span class="comtext">产品预售</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="3">
              <router-link tag="li" to="/mall">
                <div class="com">
                  <span class="comtext">竞价交易</span>
                </div>
              </router-link>
            </el-col>
            <el-col :span="3">
              <router-link tag="li" to="/news">
                <div class="com">
                  <span class="comtext">资讯</span>
                </div>
              </router-link>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Header",
  data() {
    return {
      userType: window.sessionStorage["userType"],
      isEnHeader: false,
      msgSpanCss: {
        MidLongTermSpanCss: 2,
        LoginSpanCss: 10
      },
      userCenterText: window.common.userCenterText
    };
  },

  computed: {
    ...mapGetters(["sessionInfoGetter", "userTypeGetter"])
  },

  mounted() {
    // this.setLanguageCss();
  },
  methods: {
    show: function() {},
    logout() {
      protocol.param_logout.param.firmId = this.sessionInfoGetter.firmId;
      http.postFront(protocol.param_logout).then(response => {
        let { code, msg, values } = response.data;
        if (code == "0") {
          this.$store.commit("setSessionInfo", "");
          this.$store.commit('setSelectFirmApplyCommodity',"")
          sessionStorage.clear();
          this.$router.push("/");
        }
      });
    },
    // 把语言保存到localStorage里
    handleCommand(command) {
      localStorage.setItem("lang", command);
      this.$router.go(0);
    },
    //根据语言改变样式
    setLanguageCss() {
      //防止清缓存后数据消失
      this.$i18n.locale =
        localStorage.lang || navigator.language.slice(-2).toLowerCase();
      switch (localStorage.lang) {
        case "en":
          let msgSpanCss = {
            MidLongTermSpanCss: 5,
            LoginSpanCss: 10
          };
          localStorage.setItem("msgSpanCss", JSON.stringify(msgSpanCss));
          this.msgSpanCss = JSON.parse(localStorage.msgSpanCss);
          break;
        default:
          break;
      }
    },
    goSearch() {
      this.$router.push("/search");
    }
  }
};
</script>
<style scoped lang="scss">
.headerAll {
  width: 100%;
  background-color: #f7f7f7;
}
.headerTop {
  width: 100%;
  background-color: #f2f2f2;
}
.headerTopContainer {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  line-height: 35px;
  margin: 0 auto;
}
.headerTopContainer * {
  font-size: 12px;
  color: #9999a2;
}
.headerTopContainer .exit {
  cursor: pointer;
}
.headerTopRight {
  text-align: right;
}
.headerTopRight.highlightColor {
  color: #f56c6c;
}
.headerTopLeftNav {
  text-align: right;
  overflow: hidden;
}
.headerTopLeftNav .navList {
  display: inline-block;
  margin-left: 10px;
}
.headerBottom {
  width: 1200px;
  // width: 80%;
  // min-width: 1300px;
  margin: 0 auto 10px;
}
.headerBottom .homeImgBox {
  width: 100%;
  height: 72px;
  margin: 15px 0;
  background-color: #cccccc;
  cursor: pointer;
}
.headerBottom .homeImgBox p {
  font-size: 20px;
  font-weight: 700;
  line-height: 72px;
  text-align: center;
  color: #ffffff;
}
.headerBottom .homeImg {
  width: 120px;
  margin: 15px 0;
}
.headerBottom .com {
  text-align: center;
  cursor: pointer;
}
.headerBottom .comtext {
  font-size: 16px;
  color: #666666;
}
.headerBottom .search {
  position: relative;
  width: 420px;
  margin: 15px auto;
  left: -70px;
  border: 2px solid $commonThemeColor;
  overflow: hidden;
  box-sizing: border-box;
}
.headerBottom .search .searchInput {
  float: left;
  width: 80%;
  padding-left: 15px;
  height: 30px;
  line-height: 30px;
  border: 0;
  box-sizing: border-box;
}
.headerBottom .search .searchSubmit {
  width: 20%;
  height: 30px;
  line-height: 30px;
  color: #ffffff;
  background-color: $commonThemeColor;
  border: 0;
  box-sizing: border-box;
  cursor: pointer;
}
.router-link-active span.comtext {
  color: $commonThemeColor;
  border-bottom: 2px solid $commonThemeColor;
}
</style>
