<template>
  <div class="pageContent">
    <!-- <div class="header">
      <div class="text" @click="returnPage">返回</div>
    </div> -->
    <!-- <noNavHeader title="忘记密码"/> -->
    <div class="regContainer" :class="{enForgetContainer: isEnForgetContainer}">
      <div class="logo">
          <img src="../../../assets/configImages/dllogo.png" alt="" width="50%">
          <img src="../../../assets/configImages/hydl.png" alt="" width="50%">
        </div>
      <div class="all componentBackgroundColor regInfoContainer" style="margin-top:10px">
        <!-- <el-row>
          <el-col class="backTo"><span @click="returnPage()">&lt;返回</span></el-col>
          <el-col>
            <h2 class="regTlt">忘记密码</h2> -->
            <!-- <p class="tip">温馨提示：密码修改后10分钟后生效</p> -->
            <!-- <p></p>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24"> -->
             <h2 class="jtWrap">
              <img src="../../../assets/configImages/wjmm.png" alt="" width="100%">
              <span @click="returnPage()" class="backWrap"></span>
              </h2>
            <div class="content">
            <el-form
              label-position="right"
              :model="modelReg"
              :rules="rulesReg"
              ref="regForm"
              :label-width="forgetPassLabelWidth"
              class="clsRuleReg"
            >
              <el-form-item
                label=""
                prop="mobile"
                class="whiteEleLabel"
              >
              <div class="lable">
                    账号 
                    <span>Account number</span>
                </div>
                <el-input
                  class="eleInputText"
                  v-model="modelReg.mobile"
                  placeholder="请输入您的手机号"
                ></el-input>
              </el-form-item>
              <el-form-item label="" prop="pwd" class="whiteEleLabel">
                <div class="lable lable2">
                    登录密码
                    <span>Login password </span>
                </div>
                <el-input
                  class="eleInputText"
                  :type="newPwdText"
                  v-model="modelReg.pwd"
                  placeholder="请设置新的密码"
                > <img slot="suffix" :src="closeEye" v-if="passNewType" @click="showPwd1" />
                  <img slot="suffix"  :src="openEye" v-else  @click="hidePwd1" />
                </el-input>
              </el-form-item>
              <el-form-item
                label=""
                prop="rePwd"
                class="whiteEleLabel"
              >
              <div class="lable lable2">
                    确认密码 
                    <span>Login password </span>
                </div>
                <el-input
                  class="eleInputText"
                  :type="newAginPwdText"
                  v-model="modelReg.rePwd"
                  placeholder="请再次输入密码"
                >  <img slot="suffix" :src="closeEye" v-if="newPwdType" @click="showPwd2" />
                  <img slot="suffix"  :src="openEye" v-else  @click="hidePwd2" />
                </el-input>
              </el-form-item>
              <el-form-item
                label=""
                prop="imgValidateCode"
                class="whiteEleLabel"
              >
              <div class="lable lable1">
                    图形验证码
                    <span>Graphic Verification Code</span>
                </div>
                <el-input
                  class="eleInputText imgYzmInput"
                  v-model="modelReg.imgValidateCode"
                  placeholder="请输入图形验证码"
                >
                </el-input>
                <img class="imgYzm" @click="getCap" :src="imgData">
              </el-form-item>
              <el-form-item
                label=""
                prop="msgValidateCode"
                class="whiteEleLabel"
              >
              <div class="lable lable1">
                    短信验证码
                    <span>SMS verification code</span>
                </div>
                <el-input
                  class="eleInputText msgYzmInput"
                  v-model="modelReg.msgValidateCode"
                  placeholder="请输入短信验证码"
                >
                </el-input>
                 <el-button
                    @click="getMsgYzm('regForm')"
                    :disabled="btnDisenable"
                    class="btnGetYzm"
                  >{{btnMsgYzmVal}}</el-button>
              </el-form-item>
              <el-form-item>
                <el-button
                  type="danger"
                  :disabled="btnEnable"
                  @click="submitRegForm('regForm')"
                >提  交</el-button>
              </el-form-item>
            </el-form>
            </div>
          <!-- </el-col> -->
          <!-- <el-col :span='12'>
          </el-col>-->
        <!-- </el-row> -->
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Header from "../../base/header/Header";
import md5 from "js-md5";
import noNavHeader from "../../base/header/noNavHeader";
export default {
  name: "Register",
  components: {
    Header,
    noNavHeader
  },
  data() {
    let validateRePwd = (rule, value, callback) => {
      if (value !== this.modelReg.pwd) {
        callback(
          new Error("两次输入密码不一致").message
        );
      } else {
        callback();
      }
    };
    let validateMsgCaptcha = (rule, value, callback) => {
      protocol.param_validMobileCaptcha.param.capcode = value;
      http.postFront(protocol.param_validMobileCaptcha).then(response => {
        let { code, msg, values } = response.data;
        if (code == 1) {
          callback();
        } else {
          callback(new Error(msg).message);
        }
      });
    };

    return {
      imgData: "",
      btnMsgYzmVal: "获取验证码",
      newPwdText:'password',
      newAginPwdText:'password',
      passNewType:true,
      newPwdType:true,
      closeEye:require('../../../assets/images/closeeye.png'),
      openEye:require('../../../assets/images/openeye.png'),
      btnDisenable: false,
      dialogVisible: false,
      btnEnable: false,
      modelReg: {
        mobile: "",
        pwd: "",
        rePwd: "",
        imgValidateCode: "",
        msgValidateCode: "",
        inviteCode: "",
        isAgree: false
      },
      rulesReg: {
        mobile: [
          { required: true, message: "请输入手机号", trigger: "change" },
          { pattern: /^1[3456789]\d{9}$/, message: "手机号格式不正确", trigger: "blur" }
        ],
        pwd: [
          {
            required: true,
            message: "请输入新的密码",
            trigger: "blur"
          },
          // { min: 8, max: 8, message: "请输入长度是8位的数字、字母组合密码", trigger: "blur"
          // },
          {
            // pattern: /^[0-9a-zA-Z!@#$%^&*]{8,20}$/,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,20}$/,
            message: "请输入长度是8-20位的数字、字母组合密码",
            trigger: "blur"
          }
          // { min: 11, max: 11, message: '请输入11位有效手机号码', trigger: 'blur' }
        ],
        rePwd: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur"
          },
          { validator: validateRePwd, trigger: "blur" }
          // { min: 11, max: 11, message: '请输入11位有效手机号码', trigger: 'blur' }
        ],
        imgValidateCode: [
          {
            required: true,
            message: "请输入图形验证码",
            trigger: "blur"
          }
        ],
        msgValidateCode: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
          // { validator: validateMsgCaptcha, trigger: 'blur' }
        ]
      },
      isEnForgetContainer: false,
      forgetPassLabelWidth: "100px",
      dialogVisible: false
    };
  },
  mounted() {
    this.getCap();
    this.setLanguageCss();
  },

  methods: {
    showDialog() {
      this.dialogVisible = true;
    },
    returnPage() {
      this.$router.push('/login')
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.btnMsgYzmVal = e + "s";
      if (e > 0) {
        setTimeout(function() {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.btnMsgYzmVal = "获取验证码";
        vueThis.btnDisenable = false;
      }
    },
     showPwd1(){
      this.passNewType = false
      this.newPwdText = 'text'
    },
    hidePwd1(){
      this.passNewType= true
      this.newPwdText = 'password'
    },
     showPwd2(){
      this.newPwdType = false
      this.newAginPwdText = 'text'
    },
    hidePwd2(){
      this.newPwdType= true
      this.newAginPwdText = 'password'
    },
    // 獲取短信楊正嗎
    getMsgYzm(formName) {
      this.$refs[formName].validateField("mobile", valid => {
        if (!valid) {
          this.$options.methods.setTime(60, this);
          this.btnDisenable = true;
          let that = this;

          let re = /^\w+@[a-z0-9]+\.[a-z]+$/i; /*邮箱不区分大小写*/

          protocol.param_authn_authn_sendCaptcha.param.loginAccount = this.modelReg.mobile;
          protocol.param_authn_authn_sendCaptcha.param.mark = "SMS_179220765";
          protocol.param_authn_authn_sendCaptcha.param.id = 28;
          http
            .postFront(protocol.param_authn_authn_sendCaptcha)
            .then(response => {
              let { code, message, value } = response.data;
              this.$EL_MESSAGE(response.data.message);
            });
        }
      });
    },
    //獲取圖片驗證碼
    getCap() {
      http.getRes(protocol.param_getCaptcha).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.imgData = "data:image/png;base64," + value.data;
          protocolFwd.param_updateUserPassword.param.captchaId = value.id;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    submitRegForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          protocolFwd.param_updateUserPassword.param.loginAccount = this.modelReg.mobile;
          protocolFwd.param_updateUserPassword.param.password = md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.modelReg.pwd);
          protocolFwd.param_updateUserPassword.param.captchaCode = this.modelReg.imgValidateCode;
          protocolFwd.param_updateUserPassword.param.code = this.modelReg.msgValidateCode;
          // 登錄密碼校驗請求
          http.postFront(protocolFwd.param_updateUserPassword).then(response => {
            this.$EL_MESSAGE(response.data.message);
            this.btnEnable = false;
            if (response.data.code == "0") {
              this.$router.push("/login");
            }
          });
        }
      });
    },
    //根据语言改变样式
    setLanguageCss() {
      //防止清缓存后数据消失
      this.$i18n.locale =
        localStorage.lang || navigator.language.slice(-2).toLowerCase();
      switch (localStorage.lang) {
        case "en":
          localStorage.setItem("isEnForgetContainer", "true");
          localStorage.setItem("forgetPassLabelWidth", "175px");
          this.isEnForgetContainer = localStorage.isEnForgetContainer;
          this.forgetPassLabelWidth = localStorage.forgetPassLabelWidth;
          break;
        default:
          break;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-button {
  background-color: #0F348F;
  height: 48px;
  border-radius: 8px;
  border: 0;
}
.pageContent{
  background: url("../../../assets/configImages/new_login_img.jpg") center
    center no-repeat;
  width: 100%;
  height: 100vh;
  // background: url('../../../assets/configImages/login_bg.png') center center no-repeat;
  // background-size: cover;
  // background-repeat:no-repeat;
	background-size:100% 100%;
	-moz-background-size:100% 100%;
  overflow: hidden;
}
$commonColor: rgba(78, 89, 133, 1);
$componentBgColor: rgba(32, 35, 44, 1);
.regInfoContainer {
  // margin: 0 auto;
  // padding: 15px;
  // border-radius: 2px;
  background-color: #ffffff;
  border-radius: 20px;
  padding-bottom: 40px;
  // box-shadow: 0px 0px 2px #666666;
}
.backTo{
  font-size: 18px;
  font-weight: 700;
  color: #606266 ;
  cursor: pointer;
}
.el-input,
button {
  // width: 86%;
}

.jtWrap{
  position: relative;
  .backWrap{
    cursor: pointer;
    display: inline-block;
    width: 43px;
    height: 35px;
    position: absolute;
    right: 30px;
    top: 25%;
  }
}
.tip {
  margin-left: 10%;
  color: $commonColor;
}
.el-row {
  margin-top: 10px;
}
.spanBottom {
  margin-bottom: 20px;
}
input.inputtext {
  background-color: $componentBgColor;
}
.imgYzm {
  position: absolute;
  bottom: 8px;
  right: 0;
  height: 30px;
  width: 115px;
  cursor: pointer;
}
.userProtocal {
  color: #99ccff;
}
.btnGetYzm {
  position: absolute;
  bottom: 8px;
  width: 115px;
  height: 40px;
  color: #0F348F;
  right: 0;
  border-radius: 8px;
  border: 1px solid #0F348F;
  background-color: #fff;
}
.logo{
  width: 40%;
  display: flex;
  align-items: center;

}
.all{
  width: 40%;
}
.content{
  width: 100%;
  padding: 0 50px;
  padding-top: 25px;
  box-sizing: border-box;
  padding-top: 0;
}
.all /deep/ .el-form-item__content{
  display: flex;
  flex-direction: column;
  padding-top: 34px;
  line-height: normal;
  margin-left: 0px !important;
}
.all .el-form-item{
  margin-bottom: 0px;
}
.all /deep/ .el-input__inner {
  border-radius: 0;
    border: none;
    padding: 0;
    border-bottom: 1px solid #DCDFE6;
}
.all .el-form-item__label{
  text-align: left;
}
.xyWrap{
  padding-top: 15px;
  display: flex;
  justify-content: space-between;
  padding-right: 54px;
}
.xyContent{
  /deep/ .el-form-item__content{
    padding-top: 0;
  }
}
.lable{
  font-family: Source Han Sans SC;
  font-weight: bold;
  font-size: 20px;
  color: #1C1C1C;
  padding-bottom: 10px;
  // line-height: 66px;
  span{
    font-weight: 400;
  }
  span::before{
    position: absolute;
    display: inline-block;
    content: '*';
    color: red;
    /* margin-right: 10px; */
    top: 30px;
    left: 38px;
    font-size: 23px;
  }
}
.lable1{
  span::before{
    left: 98px;
  }
}
.lable2{
  span::before{
    left: 78px;
  }
}
.regContainer {
  // width: 100%;
  // width: 550px;
  // margin: 10px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10%;

  // width: 550px;
  // margin: 10px auto;
  border-radius: 10px;
  // width: 100%;
  height: 100%;
  overflow: auto;
}
.regTlt {
  margin: 20px 0;
  text-align: center;
  font-size: 22px;
  color: #666666;
}
.tip{
  font-size:13px;
  color:#cf4640;
}
div.enForgetContainer {
  width: 650px;
}
.btnGetYzm {
  display: block;
  width: 115px;
}
</style>
<style>
.imgYzmInput .el-input-group__append {
  padding: 0;
}
</style>

